<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลการซ่อมบำรุง
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <div class="mx-4 pb-4 box box-2" style="min-height: 100%;">
      <v-card height="100%" class="px-2 pt-2">
        <v-data-table
            :headers="headers"
            :items="maintenances"
            @click:row="gotoMaintenanceDetail"
        >
          <template #item.maintenance_item="{ item }">
            {{ showItems(item.maintenance_item) }}
          </template>

        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MaintenanceByVehicle",
  data() {
    return {
      showFilter: false,
      headers: [
        {
          text: 'วันที่',
          value: 'date',
          width: '120'
        },
        {
          text: 'รถ',
          value: 'vehicle.licence',
          width: '200'
        },
        {
          text: 'รายการ',
          value: 'maintenance_item'
        },
        {
          text: 'หมายเหตุ',
          value: 'remark'
        }
      ],
      maintenances: [],
      search: ''
    }
  },
  created() {
    this.getMaintenance()
  },
  methods: {
    getMaintenance() {
      axios.get(`/api/tracks/vehicle/${this.$route.params.id}/`)
          .then(res => {
            console.log(res.data.data)
            this.maintenances = res.data.data.maintenance
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    showItems(item) {
      let string = ''
      item.forEach((i, index) => {
        string += (`${i.part.name} ${index !== item.length - 1 ? ', ' : ''}`)
      })
      return string
    },
    gotoMaintenanceDetail(row) {
      this.$router.push({path: `/maintenance/${row.id}`})
    }
  }
}
</script>

<style scoped>

</style>